/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Typography,
  message,
} from 'antd';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useState } from 'react';
import { graphql } from '../__generated__/gql';
import LayoutDashboard from '../components/Layouts/LayoutDashboard';
import useDictionary from '../lib/useDictionary';
import { errorToString, graphqlClient } from '../lib/util';

const whoamiIndexDocument = graphql(`
  query whoamiIndex {
    whoami {
      id
      firstName
      lastName
      emailAddress
      userDependentRelationships {
        id
        role
        dependentId
        dependent {
          id
          firstName
          lastName
          birthDate
        }
      }
    }
  }
`);

const setPasswordDoc = graphql(`
  mutation SetPassword($password: String!) {
    setOwnPassword(password: $password)
  }
`);

const setPinDoc = graphql(`
  mutation SetPin($pin: String!) {
    setOwnPin(pin: $pin)
  }
`);

export default function Dashboard() {
  const session = useSession({ required: true });
  const d = useDictionary();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [showChangePin, setShowChangePin] = useState<boolean>(false);
  const userQuery = useQuery({
    queryKey: ['dashboardMain', session.data?.user?.sub],
    queryFn: async () => graphqlClient.request(whoamiIndexDocument),
    onError: (e) => message.error(errorToString(e)),
  });
  const setPasswordMutation = useMutation({
    // eslint-disable-next-line max-len
    mutationFn: async ({ password }: { password: string }) => graphqlClient.request(setPasswordDoc, { password }),
    onSuccess: () => {
      message.success(d.authentication.passwordSet);
      setShowChangePassword(false);
    },
    onError: (e) => message.error(errorToString(e)),
  });
  const setPinMutation = useMutation({
    mutationFn: async ({ pin }: { pin: string }) => graphqlClient.request(setPinDoc, { pin }),
    onSuccess: () => {
      message.success(d.authentication.pinSet);
      setShowChangePin(false);
    },
    onError: (e) => message.error(errorToString(e)),
  });
  const user = userQuery.data?.whoami;

  if (!user) return <LayoutDashboard isLoading />;

  return (
    <LayoutDashboard>
      <Row gutter={[16, 16]}>
        <Col md={8}>
          <Card>
            <Typography.Title level={2}>{`${d.base.Welcome} ${user.firstName} ${user.lastName}!`}</Typography.Title>
            <Typography.Text type="secondary">ID: {user.id}</Typography.Text>
            <br />
            <Button style={{ marginTop: 16 }} onClick={() => setShowChangePassword(true)}>{d.authentication.changePassword}</Button>
            <Button style={{ marginLeft: 16 }} onClick={() => setShowChangePin(true)}>{d.authentication.changePin}</Button>
          </Card>
        </Col>
        <Col md={8} xs={24}>
          { (user?.userDependentRelationships || []).map((userDependentRelationship) => (
            <Card
              key={userDependentRelationship.id}
              style={{ marginBottom: 16 }}
            >
              <Typography.Title level={3}>{`${userDependentRelationship.dependent.firstName} ${userDependentRelationship.dependent.lastName}`}</Typography.Title>
              <Typography.Text type="secondary">{d.base['Birth Date']}: {userDependentRelationship.dependent.birthDate}</Typography.Text>
              <Link href={`/dependents/${userDependentRelationship.dependent.id}`} target="_blank">
                <Button block style={{ marginTop: 16 }}>{d.base.Attendance}</Button>
              </Link>
            </Card>
          ))}
        </Col>
      </Row>
      <Drawer
        // title="Details"
        onClose={() => setShowChangePassword(false)}
        open={showChangePassword}
        destroyOnClose
      >
        <div>
          <Form
            layout="vertical"
            onFinish={(values) => {
              if (values.password !== values.passwordConfirm) {
                message.error(d.authentication.passwordMustMatch);
                return;
              }
              setPasswordMutation.mutate({ password: values.password });
            }}
          >
            <Form.Item
              label={d.authentication.newPassword}
              name="password"
              rules={[{ required: true, message: d.authentication.passwordMustBeAtLeast6Characters }, { min: 6, message: d.authentication.passwordMustBeAtLeast6Characters }]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label={d.authentication.confirmNewPassword}
              name="passwordConfirm"
              rules={[{ required: true, message: d.authentication.passwordMustBeAtLeast6Characters }]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={setPasswordMutation.isLoading}
              >
                {d.authentication.savePassword}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
      <Drawer
        onClose={() => setShowChangePin(false)}
        open={showChangePin}
        destroyOnClose
      >
        <div>
          <Form
            layout="vertical"
            onFinish={(values) => {
              if (values.pin !== values.pinConfirm) {
                message.error(d.authentication.pinMustMatch);
                return;
              }
              setPinMutation.mutate({ pin: values.pin });
            }}
          >
            <Form.Item
              label={d.authentication.newPin}
              name="pin"
              rules={[{ required: true, message: d.authentication.pinMustBeAtLeast6Characters }, { min: 6, message: d.authentication.pinMustBeAtLeast6Characters }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label={d.authentication.confirmNewPin}
              name="pinConfirm"
              rules={[{ required: true, message: d.authentication.pinMustBeAtLeast6Characters }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
            >
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={setPinMutation.isLoading}
              >
                {d.authentication.savePin}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </LayoutDashboard>
  );
}
